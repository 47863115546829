var site = site || {};

site.account = site.account || {};
var generic = generic || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
(function ($) {
  Drupal.behaviors.gnavAccountV2 = {
    attach: function (context) {
      var $template = $('.js-gnav-account', context);
      var $inputTrigger = $('#gnav_util_trigger_account', context);
      var $accountLinkTrigger = $('.js-gnav-util-trigger--account', context);
      var $accountContent = $('.js-gnav-account-content', $template);
      var $headerMain = $('.site-header__wrapper', context);
      var $registerEmailField = $('.register-email', $accountContent);
      var $passwordFieldRegister = $(
        '.js-gnav-account-content-container-forms-register-password',
        $accountContent
      );
      var $passwordFieldLogin = $('.js-login-password', $accountContent);
      var $validationPasswordRulesContainer = $(
        '.js-gnav-account-content-container-forms-register-container-form-info',
        $accountContent
      );
      var $html = $('html');
      var firstName = site.userInfoCookie.getValue('first_name');

      if (firstName && firstName.indexOf('+') != -1) {
        firstName = firstName.replace(/\+/g, ' ');
      }

      if (parseInt(site.userInfoCookie.getValue('signed_in')) === 0) {
        $accountContent.removeClass('is-signed-in');
      } else if (parseInt(site.userInfoCookie.getValue('signed_in')) === 1) {
        $accountContent.addClass('is-signed-in');
      }

      $passwordFieldRegister.once().on('focusin keyup', function () {
        var value = $(this).val();
        var $rulesItems = $validationPasswordRulesContainer.find('li');
        var rules = {
          contains_lower_case_chars: /[a-z]/,
          contains_uppercase_chars: /[A-Z]/,
          contains_numbers: /[0-9]/,
          minimum_length: /.{8,}/
        };

        $validationPasswordRulesContainer.removeClass('hidden');

        $.each($rulesItems, function (i, elm) {
          var valid;
          var ruleName = $(elm).data('rule');

          if (rules[ruleName]) {
            valid = new RegExp(rules[ruleName]).test(value);
            $(elm).toggleClass('pass', valid);
          }
        });
      });

      $passwordFieldRegister.focusout(function () {
        $validationPasswordRulesContainer.addClass('hidden');
      });

      $accountLinkTrigger
        .off('click.siteHeader touchstart.siteHeader')
        .on('click.siteHeader touchstart.siteHeader', function () {
          $inputTrigger.prop('checked', true);
          $html.toggleClass('active-utility-overlay', true);
          // Remove active-gnav class from html
          $html.toggleClass('active-gnav', false);
          if ($template.hasClass('unregistered-user')) {
            setOverlayHeight();
          }
        });

      // Close utility overlay when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click mouseout mouseover',
        _.debounce(function (event) {
          // Avoid mouseout on hover input field
          if (event.type !== 'click') {
            if (
              event.relatedTarget === null ||
              event.relatedTarget.nodeName.toLowerCase() === 'input'
            ) {
              return;
            }
          }

          if (
            $html.hasClass('active-utility-overlay') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            $html.toggleClass('active-utility-overlay', false);
            $inputTrigger.prop('checked', false);
          }
        }, 300)
      );

      $accountContent.each(function () {
        var $block = $(this);
        var $registerForm = $(
          '.js-gnav-account-content-container-forms-register-container-form',
          $block
        );
        var $signInForm = $(
          '.js-gnav-account-content-container-forms-signin-container-form',
          $block
        );
        var $userName = $('.js-gnav-account-content-container-forms-account-links-name', $block);

        $userName.html(firstName);

        $registerForm.once().on('submit', function (event) {
          var error = false;

          error = validateInputsRequired($(this));
          if (error) {
            event.preventDefault();
          }
        });

        $signInForm.once().on('submit', function (event) {
          var $this = $(this);
          var error = false;
          var $accountTemplate = $this.closest('.js-gnav-account');
          var email = $this.find('.js-login-email').val();

          error = validateInputsRequired($this);
          if (error) {
            event.preventDefault();
          }

          if (!$accountTemplate.hasClass('registered-user')) {
            event.preventDefault();
            generic.jsonrpc.fetch({
              method: 'user.exists',
              params: [{ EMAIL_ADDRESS: email }],
              onSuccess: function (jsonRpcResponse) {
                var isRegistered = jsonRpcResponse.getValue().registered;

                if (parseInt(isRegistered) === 1) {
                  // Add class to display complete login form
                  $accountTemplate.addClass('registered-user');
                  // If the user exist then make password field required
                  $passwordFieldLogin.addClass('required');
                } else {
                  // Add class to display complete register form
                  $accountTemplate.addClass('unregistered-user');
                  $registerEmailField.val(email);
                  setOverlayHeight();

                  return;
                }
              }
            });
          }
        });
      });

      function validateInputsRequired($element) {
        var error = false;

        if ($element.length === 0) {
          return true;
        }
        $element.find('input').each(function () {
          var $this = $(this);

          if ($this.hasClass('required')) {
            if (!$this.val() || ($this.attr('type') === 'checkbox' && !$this.prop('checked'))) {
              error = true;
              $this.addClass('error');
            } else {
              $this.removeClass('error');
            }
          }
        });

        return error;
      }

      function setOverlayHeight() {
        var siteHeaderHeight = $headerMain.outerHeight(true);
        var overlayHeight = $(window).height() - siteHeaderHeight;

        $accountContent.height('auto');
        $accountContent.css('max-height', overlayHeight);
      }
      // Function needed for account page account/signin.tmpl
      site.account.initPasswordValidationTooltip(context);
    }
  };
})(jQuery);

// Functions ported from sign_in_v1 template and needed for account pages account/signin.tmpl
site.account.getPassDOMNodes = function ($context) {
  site.account.passwordInfo = $('.js-password-field-info', $context);
};

site.account.validatePasswordRules = function () {
  site.account.passwordInfo.removeClass('hidden');
  var $input = $(this);
  var value = this.value;
  var $rulesItems = $input.closest('.form-item').find('.js-password-field-rules li');
  var rules = {
    contains_lower_case_chars: /[a-z]/,
    contains_uppercase_chars: /[A-Z]/,
    contains_numbers: /[0-9]/,
    minimum_length: /.{8,}/
  };

  this.classList.toggle('hasValue', this.value);
  $rulesItems.each(function () {
    var $this = $(this);
    var valid;
    var ruleName = $this.data('rule');

    if (rules[ruleName]) {
      valid = new RegExp(rules[ruleName]).test(value);
      $this.toggleClass('pass', valid);
    }
  });
};

site.account.initPasswordValidationTooltip = function ($context) {
  var $passwordContainer = $('.js-password-field', $context);
  var $password = $('input[type="text"], input[type="password"]', $passwordContainer);

  site.account.getPassDOMNodes($context);
  if ($(window).width() === 1024 && $('.js-signin-block', $context).hasClass('signin-overlay')) {
    site.account.passwordInfo.removeClass('hidden');
  }
  $password.off('input focus').on('input focus', site.account.validatePasswordRules);
  $password.off('blur').on('blur', function () {
    site.account.passwordInfo.addClass('hidden');
  });
};
